<template>
    <div>
        <div
            v-if="step === 'summary'"
            class="d-flex align-items-center w-100"
            style="
                border-bottom: 1px solid #FF7F32;
                background-color: #FFF7EF;  
                padding: 12px;
            "
        >
            <img
                src="/assets/img/icon/warning-icon-orange.svg"
                width="20"
                height="20"
                alt=""
            />
            <p class="f-12 mb-0" style="flex: 1; color: #FF7F32; margin-left: 8px;">
                Please double check information below and make sure you input information correctly: selected user, clinic, and treatment.
            </p>
        </div>
        <div class="section mw-100 p-2" v-if="step === 'form'">
            <ValidationObserver ref="observer" slim>
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <div class="card">
                            <div class="card-body">
                                <p class="f-16 font-600">Treatment Information</p>
                                <hr>
                                <div class="d-flex flex-column gap-1">
                                    <div class="d-flex gap-1 align-items-center">
                                        <p class="mb-0 f-14" style="flex: 0 0 160px;">
                                            Doctor Consultation
                                        </p>
                                        <div style="flex: 0 0 auto;">
                                            <label class="d-flex align-items-center gap-0.5">
                                                <input
                                                    type="checkbox"
                                                    v-model="form.withConsultation"
                                                    :disabled="(user.id && !user.hasTreatmentHistory) || isLoading"
                                                    @change="getAvailableSchedule()"
                                                >
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div class="d-flex gap-1 align-items-start flex-wrap">
                                        <p class="mb-0 f-14" style="flex: 0 0 160px; padding-top: 9.5px;">
                                            Treatment
                                        </p>
                                        <div class="d-flex flex-column" style="flex: 1; min-width: 200px;">
                                            <div v-if="sortedTreatmentsByPriority.length > 0" class="selected-treatments">
                                                <div
                                                    v-for="(selectedTreatment, selectedTreatmentIdx) in sortedTreatmentsByPriority"
                                                    class="d-flex align-items-center gap-0.5"
                                                    :style="`
                                                        padding: 8px 0;
                                                        border-bottom: ${
                                                            selectedTreatmentIdx === sortedTreatmentsByPriority.length - 1
                                                                ? '0'
                                                                : '1px'
                                                        } solid #bcc0d1;
                                                    `"
                                                    :key="selectedTreatmentIdx"
                                                >
                                                    <div class="d-flex align-items-center gap-0.5" style="flex: 1;">
                                                        <span class="text-truncate">
                                                            <template v-if="selectedTreatment.treatmentGroup">
                                                                {{ `${selectedTreatment.treatmentGroup.name} - ` }}
                                                            </template>
                                                            {{ selectedTreatment.title }}
                                                        </span>
                                                        <div v-if="selectedTreatment.type === 'ADD_ON'" class="flex-shrink-0">
                                                            <Badge textColor="#5B5E62" bgColor="#F2F4F6">Add On</Badge>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="d-flex align-items-center cursor-pointer"
                                                        style="flex: 0 0 auto"
                                                        @click="removeTreatment(selectedTreatment.id)"
                                                    >
                                                        <img
                                                            src="/assets/img/icon/delete-icon-red.svg"
                                                            width="20"
                                                            height="20"
                                                            alt=""
                                                        />
                                                        <span style="color: #EB5757; margin-left: 4px;">
                                                            Delete
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <InputSelect
                                                :options="filteredTreatmentOptions"
                                                placeholder="Select Treatment"
                                                :searchable="true"
                                                :disabled="isLoading"
                                                @search="getTreatmentOptions"
                                                trackBy="id"
                                                @input="(newTreatment) => addNewTreatment(newTreatment)"
                                            >
                                                <template #option="{ option }">
                                                    <span class="text-truncate">
                                                        <template v-if="option.treatmentGroup">
                                                            {{ `${option.treatmentGroup.name} - ` }}
                                                        </template>
                                                        {{ option.title }}
                                                        {{ option.type === 'ADD_ON' ? '(Add On)' : '' }}
                                                    </span>
                                                </template>
                                            </InputSelect>
                                            <ValidationProvider v-slot="{validated}">
                                                <small class="text-danger" v-if="!isValidTreatment && validated">
                                                    This treatment field is required
                                                </small>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div
                                        v-if="form.withConsultation || isSomeTreatmentRequireDoctor"
                                        class="d-flex gap-1 align-items-center flex-wrap"
                                    >
                                        <p class="mb-0 f-14" style="flex: 0 0 160px;">
                                            Doctor's Preference
                                        </p>
                                        <div style="flex: 1; min-width: 200px;">
                                            <InputSelect
                                                v-model="form.doctor"
                                                :options="options.doctor"
                                                trackBy="id"
                                                label="adminName"
                                                placeholder="Select Doctor Name"
                                                :clearable="false"
                                                :searchable="true"
                                                @search="getDoctorOptions"
                                                :disabled="isLoading"
                                                @input="getAvailableSchedule()"
                                                :customLabel="({ id, adminName, isRegisteredOnSelectedClinic = false }) => {
                                                    let status = '';
                                                    if (id > 0 && !isRegisteredOnSelectedClinic) {
                                                        status = '⚫️ ';
                                                    }
                                                    return `${status}${adminName}`
                                                }"
                                            >
                                                <template #option="{ option }">
                                                    <span class="text-truncate">
                                                        <template v-if="option.id > 0 && !option.isRegisteredOnSelectedClinic">
                                                            {{ `⚫️ ` }}
                                                        </template>
                                                        {{ option.adminName }}
                                                    </span>
                                                </template>
                                            </InputSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <p class="f-16 font-600">Booking Information</p>
                                <hr>
                                <div id="booking-date-description" v-if="description !== null">
                                    <div style="flex: 0 0 auto">
                                        <img
                                            src="/assets/img/icon/warning-icon-orange.svg"
                                            width="20"
                                            height="20"
                                            alt=""
                                        />
                                    </div>
                                    <div v-html="description" />
                                </div>
                                <div class="d-flex flex-wrap align-items-start" style="gap: 24px;">
                                    <div style="flex: 0 0 auto;" id="schedule-date">
                                        <component v-bind:is="'style'" type="text/css" v-if="options.availableDates.length > 0">
                                            <template v-for="date in options.availableDates">
                                                <template v-if="['BOOKED','UNAVAILABLE'].includes(date.availability)">
                                                    #schedule-date .mx-calendar-content .cell[title="{{ date.date }}"] {
                                                        border: 1px solid #FFAE62;
                                                    }
                                                    #schedule-date .mx-calendar-content .cell[title="{{ date.date }}"]:hover {
                                                        background-color: #EBF8FA;
                                                        border: 1px solid #0072CD;
                                                    }
                                                    #schedule-date .mx-calendar-content .cell.active[title="{{ date.date }}"] {
                                                        background-color: #EBF8FA;
                                                        border: 1px solid #0072CD !important;
                                                    }
                                                </template>
                                            </template>
                                        </component>
                                        <date-picker
                                            inline
                                            v-model="form.date"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            :disabled-date="(selectedValue) => generateDateOptions(selectedValue)"
                                            :clearable="false"
                                            @input="getAvailableSchedule()"
                                        />
                                    </div>
                                    <div class="position-relative" id="available-schedule">
                                        <div
                                            :style="`
                                                overflow-x: auto;
                                                opacity: ${isLoading ? 0.35 : 1};
                                            `"
                                        >
                                            <TabsV2
                                                :tabs="[
                                                    { value: 'morning', label: 'Morning'},
                                                    { value: 'afternoon', label: 'Afternoon'},
                                                    { value: 'evening', label: 'Evening'},
                                                ]"
                                                :activeTab="activeTab"
                                                @change="(newTab) => activeTab = newTab"
                                                :styleObject="{ width: '310px' }"
                                            ></TabsV2>
                                        </div>
                                        <div
                                            v-if="isFormCompleted && options.availableSchedule[activeTab.value].length > 0"
                                            class="d-flex flex-wrap align-items-center"
                                            :style="`
                                                margin: 21px -4px 0 -4px;
                                                opacity: ${isLoading ? 0.25 : 1};
                                            `"
                                        >
                                            <div
                                                style="flex: 0 0 50%; padding: 4px;"
                                                v-for="(schedule, scheduleIdx) in options.availableSchedule[activeTab.value]"
                                                :key="scheduleIdx"
                                            >
                                                <VTooltip>
                                                    <button
                                                        type="button"
                                                        class="btn-schedule-list"
                                                        :class="{
                                                            'btn-schedule-list-disabled': !schedule.isAvailable,
                                                            'btn-schedule-list-out-ops-hours': !schedule.isOperationalHour && schedule.isAvailable,
                                                            'btn-schedule-list-out-doctor-schedule': !schedule.isInOfflineClinicTeamSchedule && schedule.isAvailable,
                                                            'btn-schedule-list-selected': (schedule.time === form.time),
                                                        }"
                                                        :disabled="!schedule.isAvailable"
                                                        @click="(!isLoading && schedule.isAvailable) && setScheduleTime(schedule.time)"
                                                    >
                                                        {{ schedule.time }}
                                                    </button>
                                                    <template #popper>
                                                        {{  generateScheduleTimeTooltip(schedule)  }}
                                                    </template>
                                                </VTooltip>
                                            </div>
                                        </div>
                                        <div
                                            v-else
                                            :style="`
                                                opacity: ${isLoading ? 0.35 : 1};
                                            `"
                                        >
                                            <div
                                                v-if="isFormCompleted && !options.availableSchedule[activeTab.value].length"
                                                style="padding: 20px 8px 0 8px;"
                                            >
                                                <p class="f-14 text-center mb-0">
                                                    No available schedule.
                                                </p>
                                            </div>
                                            <template v-else>
                                                <div class="available-schedule-empty-state">
                                                    <img
                                                        style="width: 100%; height: auto;"
                                                        src="/assets/img/available-schedule-empty-state.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <p class="f-14 text-center mb-0" style="padding-inline: 8px;">
                                                    To choose available schedule, please select treatment and clinic first.
                                                </p>
                                            </template>
                                        </div>
                                        <div class="schedule-loader" v-if="isLoading">
                                            <div class="d-flex justify-content-center" style="font-size: 48px;">
                                                <i class="fa fa-spinner fa-spin"></i>
                                            </div>
                                            <p class="f-14 text-black text-center mt-1 mb-0 px-2">
                                                Please wait, we’re checking the available schedule.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div id="legend-schedule">
                                    <div class="btn-schedule-list btn-legend-schedule">Available</div>
                                    <div class="btn-schedule-list-out-doctor-schedule btn-legend-schedule">Outside Doctor's Schedule</div>
                                    <div class="btn-schedule-list-out-ops-hours btn-legend-schedule">Outside Ops Hours</div>
                                    <div class="btn-schedule-list-disabled btn-legend-schedule">Unavailable</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="card">
                            <div class="card-body f-14">
                                <p class="f-16 font-600">Clinic Information</p>
                                <hr>
                                <ValidationProvider
                                    name="clinic name"
                                    v-slot="{ errors }"
                                    rules="required"
                                    slim
                                >
                                    <div class="d-flex flex-column gap-0.25">
                                        <p class="mb-0 f-12 font-600">Clinic Name</p>
                                        <InputSelect
                                            v-model="form.clinic"
                                            :options="options.clinic"
                                            trackBy="id"
                                            label="name"
                                            placeholder="Select Clinic Name"
                                            :clearable="false"
                                            :disabled="isLoading"
                                            @input="(newClinic) => handleSelectClinic(newClinic)"
                                        />
                                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body f-14">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="f-16 font-600 mb-0" style="flex: 1;">User Information</p>
                                    <div
                                        v-if="user.id && !showSearchUser"
                                        @click="showSearchUser = true;"
                                        class="f-14 cursor-pointer"
                                        style="flex: 0 0 auto; color: #0072CD;"
                                    >
                                        Change User
                                    </div>
                                </div>
                                <hr>
                                <template v-if="showSearchUser">
                                    <div class="position-relative">
                                        <div
                                            class="position-absolute"
                                            style="
                                                flex: 0 0 110px;
                                                top: 0;
                                                left: 0;
                                                z-index: 1;
                                            "
                                        >
                                            <InputSelect
                                                :disabled="isLoading"
                                                :options="[
                                                    { value: 'name', label: 'Name' },
                                                    { value: 'mobileNumber', label: 'Phone Number' },
                                                    { value: 'email', label: 'Email' },
                                                ]"
                                                v-model="searchUserParam"
                                                :clearable="false"
                                                width="110px"
                                                :styleObject="{
                                                    borderTopRightRadius: '0px',
                                                    borderBottomRightRadius: '0px',
                                                }"
                                            />
                                        </div>
                                        <InputSelect
                                            :disabled="isLoading"
                                            :options="options.user"
                                            placeholder="Search..."
                                            :clearable="false"
                                            :searchable="true"
                                            @search="getUserOptions"
                                            @input="(userValue) => {
                                                user = userValue;
                                                showSearchUser = false;
                                                if (!userValue.hasTreatmentHistory) {
                                                    form.withConsultation = true;
                                                }
                                                getUserOptions();
                                                getAvailableDates();
                                            }"
                                            :styleObject="{ paddingLeft: '122px' }"
                                            trackBy="id"
                                            label="name"
                                        >
                                            <template #option="{ option }">
                                                <div class="d-flex align-items-center gap-0.5">
                                                    <div class="circle flex-shrink-0">
                                                        <img
                                                            v-if="option.avatar" 
                                                            :src="option.avatar"
                                                            width="32"
                                                            height="32"
                                                            alt=""
                                                        />
                                                        <span
                                                            v-else
                                                            class="initial-user-name"
                                                        >
                                                            {{ option.name[0].toUpperCase() }}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex align-items-center" style="gap: 4px;">
                                                            <span v-if="option.isVip">👑</span>
                                                            <img
                                                                v-if="option.isSubscribed"
                                                                src="/assets/img/icon/diri-xtra-logo.svg"
                                                                width="20"
                                                                height="20"
                                                                alt=""
                                                            />
                                                            <p class="f-14 m-0 text-black text-truncate">
                                                                {{ option.name }}
                                                            </p>
                                                        </div>
                                                        <p class="f-12 m-0 text-truncate" style="color: #798EA5;">
                                                            {{ option.mobileNumber }}
                                                            {{ option.mobileNumber && option.email ? ' - ' : '' }}
                                                            {{ option.email }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </template>
                                        </InputSelect>
                                    </div>
                                    <ValidationProvider v-slot="{validated}">
                                        <small class="text-danger" v-if="validated">
                                            This user field is required
                                        </small>
                                    </ValidationProvider>
                                    <div
                                        v-if="!user.id"
                                        @click="openCreateUserModal()"
                                        class="cursor-pointer"
                                        style="color: #0072CD !important; margin-top: 13px; width: fit-content;"
                                    >
                                        + Create new account
                                    </div>
                                </template>
                                <template v-if="user.id">
                                    <div class="row mb-1" :class="{ 'mt-1': showSearchUser }">
                                        <div class="col-5 text-secondary">User Name</div>
                                        <div class="col-7 font-500 text-right">
                                            <span v-if="user.isVip" class="mr-0.25">👑</span>
                                            <img
                                                v-if="user.isSubscribed"
                                                src="/assets/img/icon/diri-xtra-logo.svg"
                                                width="20"
                                                height="20"
                                                class="mr-0.25"
                                                alt=""
                                            />
                                            <span>{{ user.name || '-' }}</span>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-5 text-secondary">Phone Number</div>
                                        <div class="col-7 font-500 text-right">{{ user.mobileNumber || '-' }}</div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-5 text-secondary">Email</div>
                                        <div class="col-7 font-500 text-right">{{ user.email || '-' }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5 text-secondary">Omnicare EMR</div>
                                        <div class="col-7 font-500 text-right">{{ user.omnicarePatientId || '-' }}</div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="btn-group-container">
                            <button
                                class="btn btn-cancel rounded w-100 m-0"
                                @click="openConfirmationExitPageModal()"
                            >
                                Cancel
                            </button>
                            <button
                                class="btn btn-save rounded w-100 m-0"
                                :disabled="isLoading"
                                @click="onSave()"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="section mw-100 p-2" v-else>
            <div class="row">
                <div class="col-12 col-lg-8">
                    <div class="card">
                        <div class="card-body">
                            <p class="f-16 font-600">Treatment Information</p>
                            <hr>
                            <div style="overflow-x: auto;">
                                <div class="d-flex gap-1 w-100" style="min-width: 600px;">
                                    <div
                                        class="d-flex gap-1 pr-1"
                                        style="
                                            flex: 1 1 33.3%;
                                            border-right: 1px solid #E4E8ED;
                                        "
                                    >
                                        <img
                                            class="clinic-booking-rounded-img-size-48"
                                            src="/assets/img/clinic-location.png"
                                            alt=""
                                        />
                                        <p class="f-14 mb-0 mt-0.25">
                                            <span class="font-600">Clinic Name</span>
                                            <br />
                                            <span>{{ summaryData.clinicName }}</span>
                                        </p>
                                    </div>
                                    <div
                                        class="d-flex gap-1 pr-1"
                                        style="
                                            flex: 1 1 33.3%;
                                            border-right: 1px solid #E4E8ED;
                                        "
                                    >
                                        <img
                                            class="clinic-booking-rounded-img-size-48"
                                            src="/assets/img/clinic-schedule.png"
                                            alt=""
                                        />
                                        <p class="f-14 mb-0 mt-0.25">
                                            <span class="font-600">{{ summaryData.treatmentDate }}</span>
                                            <br />
                                            <span>{{ summaryData.treatmentTime }}</span>
                                        </p>
                                    </div>
                                    <div
                                        class="d-flex gap-1 pr-1"
                                        style="flex: 1 1 33.3%;"
                                    >
                                        <img
                                            class="clinic-booking-rounded-img-size-48"
                                            :src="summaryData.doctor.avatar || '/assets/img/clinic-team.png'"
                                            alt=""
                                        />
                                        <p class="f-14 mb-0 mt-0.25">
                                            <span class="font-600">Doctor</span>
                                            <br />
                                            <span>{{ summaryData.doctor.name }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div style="overflow-x: auto; margin-top: 20px;">
                                <div
                                    style="
                                        overflow: hidden;
                                        border: 1px solid #D9D9D9;
                                        border-radius: 8px;
                                        min-width: 600px;
                                    "
                                >
                                    <div
                                        class="d-flex gap-1"
                                        style="
                                            padding: 12px 16px;
                                            background-color: #F2F4F6;
                                            border-bottom: 1px solid #D9D9D9;
                                        "
                                    >
                                        <div class="d-flex align-items-center gap-1">
                                            <div
                                                class="d-flex align-items-center gap-0.25"
                                                style="
                                                    padding-right: 1rem;
                                                    border-right: 1px solid #D9D9D9;
                                                "
                                            >
                                                <img
                                                    src="/assets/img/icon/clock-icon-grey.svg"
                                                    width="16"
                                                    height="16"
                                                    alt=""
                                                />
                                                <p class="f-14 mb-0">
                                                    Estimated time
                                                    <span class="text-black font-600">
                                                        {{ parseMinutesToTimeString(summaryData.estimatedMinutes) }}
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="d-flex align-items-center gap-0.25">
                                                <img
                                                    src="/assets/img/icon/receipt-icon-grey.svg"
                                                    width="16"
                                                    height="16"
                                                    alt=""
                                                />
                                                <p class="f-14 mb-0">
                                                    Estimated price
                                                    <span class="text-black font-600">
                                                        {{ summaryData.estimatedTotalPrice | currency }}
                                                        {{ `${summaryData.estimatedTotalPrice === 0 ? ' (Free)' : ''}` }}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="padding-inline: 20px;">
                                        <table class="table" style="margin-bottom: 0;">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style="width: 450px;"
                                                        class="font-600 text-black text-left align-top p-0 td-border-bottom"
                                                    >
                                                        Treatment
                                                    </td>
                                                    <td class="font-600 text-black text-left align-top p-0 td-border-bottom">
                                                        Duration
                                                    </td>
                                                    <td class="font-600 text-black text-left align-top p-0 td-border-bottom">
                                                        Price
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(treatment, treatmentIdx) in summaryData.treatmentInformation"
                                                    :key="treatmentIdx"
                                                >
                                                    <td
                                                        class="font-400 text-left align-top p-0 pr-1"
                                                        :class="`${
                                                            treatmentIdx === summaryData.treatmentInformation.length - 1
                                                                ? 'td-no-border-bottom' : 'td-border-bottom'
                                                        }`"
                                                    >
                                                        <template v-if="treatment.treatmentGroup">
                                                            {{ `${treatment.treatmentGroup.name} - ` }}
                                                        </template>
                                                        {{ treatment.title }}
                                                        <template v-if="treatment.type === 'ADD_ON'">
                                                            <Badge textColor="#5B5E62" bgColor="#F2F4F6">Add On</Badge>
                                                        </template>
                                                    </td>
                                                    <td
                                                        class="font-400 text-left align-top p-0 pr-1"
                                                        :class="`${
                                                            treatmentIdx === summaryData.treatmentInformation.length - 1
                                                                ? 'td-no-border-bottom' : 'td-border-bottom'
                                                        }`"
                                                    >
                                                        {{ treatment.durationInMinutes }} minutes
                                                    </td>
                                                    <td
                                                        class="font-400 text-left align-top p-0"
                                                        :class="`${
                                                            treatmentIdx === summaryData.treatmentInformation.length - 1
                                                                ? 'td-no-border-bottom' : 'td-border-bottom'
                                                        }`"
                                                    >
                                                        {{ treatment.price | currency }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="card">
                        <div class="card-body f-14">
                            <div class="d-flex justify-content-between align-items-center">
                                <p class="f-16 font-600 mb-0" style="flex: 1;">User Information</p>
                                <div
                                    v-if="user.id"
                                    @click="openEditUserModal()"
                                    class="d-flex align-items-center cursor-pointer"
                                    style="flex: 0 0 auto"
                                >
                                    <img
                                        src="/assets/img/icon/edit-icon-blue.svg"
                                        width="20"
                                        height="20"
                                        alt=""
                                    />
                                    <span
                                        class="f-14"
                                        style="color: #0072CD; margin-left: 4px;"
                                    >
                                        Edit
                                    </span>
                                </div>
                            </div>
                            <hr>
                            <div class="row mb-1">
                                <div class="col-5 text-secondary">User Name</div>
                                <div class="col-7 font-500 text-right">
                                    <span v-if="user.isVip" class="mr-0.25">👑</span>
                                    <img
                                        v-if="user.isSubscribed"
                                        src="/assets/img/icon/diri-xtra-logo.svg"
                                        width="20"
                                        height="20"
                                        class="mr-0.25"
                                        alt=""
                                    />
                                    <span>{{ user.name || '-' }}</span>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-5 text-secondary">Phone Number</div>
                                <div class="col-7 font-500 text-right">{{ user.mobileNumber || '-' }}</div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-5 text-secondary">Email</div>
                                <div class="col-7 font-500 text-right">{{ user.email || '-' }}</div>
                            </div>
                            <div class="row">
                                <div class="col-5 text-secondary">Omnicare EMR</div>
                                <div class="col-7 font-500 text-right">{{ user.omnicarePatientId || '-' }}</div>
                            </div>
                        </div>
                    </div>
                    <button
                        class="btn btn-copy rounded w-100 m-0 mt-1"
                        @click="handleCopyTreatmentInformation()"
                    >
                        <img
                            src="/assets/img/icon/copy-icon.svg"
                            width="20"
                            height="20"
                            alt=""
                        />
                        Copy Treatment Information
                    </button>
                    <hr />
                    <div class="d-flex flex-column gap-1">
                        <button
                            class="btn btn-edit-appointment rounded w-100 m-0"
                            @click="toggleStep()"
                        >
                            Edit Appointment
                        </button>
                        <button
                            class="btn btn-save rounded w-100 m-0"
                            @click="openConfirmationBookAppointmentModal()"
                        >
                            Book Appointment
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit user -->
        <modal
            :name="EDIT_USER_MODAL"
            :adaptive="true"
            height="auto"
            :width="800"
            style="z-index: 2000"
        >
            <div class="modal-container w-100 p-0">
                <p v-if="isUserDataLoading" style="padding: 21px;">Loading...</p>
                <div
                    v-else
                    style="
                        max-height: 500px;
                        overflow-y: auto;
                    "
                >
                    <UserForm
                        :userId="user.id"
                        :userProps="user"
                        pageType="edit-modal"
                        saveLabel="Save"
                        @save="
                            closeEditUserModal();
                            getUserData();
                        "
                        @cancel="closeEditUserModal()"
                    ></UserForm>
                </div>
            </div>
        </modal>
        <!-- Modal create user -->
        <modal
            :name="CREATE_USER_MODAL"
            :adaptive="true"
            height="auto"
            :width="800"
            style="z-index: 2000"
        >
            <div class="modal-container w-100 p-0">
                <div style="max-height: 500px; overflow-y: auto;">
                    <UserForm
                        pageType="add-modal"
                        saveLabel="Save"
                        @save="(newUser) => {
                            user = {
                                ...newUser,
                                hasTreatmentHistory: false,
                                isSubscribed: false,
                                isVip: false,
                            }
                            showSearchUser = false;
                            form.withConsultation = true;
                            getAvailableDates();
                            closeCreateUserModal();
                        }"
                        @cancel="closeCreateUserModal()"
                    ></UserForm>
                </div>
            </div>
        </modal>
        <!-- Modal confirmation exit page -->
        <modal :name="CONFIRMATION_EXIT_PAGE_MODAL" :adaptive="true" height="auto" :width="500" style="z-index: 2000;">
            <div class="modal-container p-2">
                <p class="f-16 text-black font-600" style="padding-right: 32px;">Are you sure you want to exit this page?</p>
                <hr>
                <div class="d-flex justify-content-end gap-1">
                    <button
                        style="padding: 12.6px 24px"
                        class="btn btn-cancel rounded m-0"
                        @click="closeConfirmationExitPageModal()"
                    >
                        No
                    </button>
                    <button
                        style="padding: 12.6px 48px"
                        class="btn btn-save rounded m-0"
                        @click="closeConfirmationExitPageModal(true)"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </modal>
        <!-- Modal confirmation book appointment -->
        <modal :name="CONFIRMATION_BOOK_APPOINTMENT_MODAL" :adaptive="true" height="auto" :width="500" style="z-index: 2000;">
            <div class="modal-container p-2">
                <p class="f-16 text-black font-600" style="padding-right: 32px;">Are you sure you want to create this appointment?</p>
                <hr>
                <div class="d-flex justify-content-end gap-1">
                    <button
                        style="padding: 12.6px 24px"
                        class="btn btn-cancel rounded m-0"
                        @click="closeConfirmationBookAppointmentModal()"
                    >
                        No
                    </button>
                    <button
                        style="padding: 12.6px 48px"
                        class="btn btn-save rounded m-0"
                        @click="closeConfirmationBookAppointmentModal(true)"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import InputSelect from '@/components/InputSelect';
    import TabsV2 from '@/components/TabsV2';
    import Badge from '@/components/Badge';
    import UserForm from './components/UserForm.vue';

    const CONFIRMATION_EXIT_PAGE_MODAL = 'confirmation_exit_page_modal';
    const CONFIRMATION_BOOK_APPOINTMENT_MODAL = 'confirmation_book_appointment_modal';
    const EDIT_USER_MODAL = 'edit-user-modal';
    const CREATE_USER_MODAL = 'create-user-modal';

    export default {
        components: {
            InputSelect,
            TabsV2,
            Badge,
            UserForm,
        },
        data() {
            return {
                step: 'form', // form, summary
                user: {},
                form: {
                    clinic: null,
                    treatments: [],
                    doctor: { id: 0, adminName: 'Any Doctor', avatar: '' },
                    // withConsultation forced to be true if user.hasTreatmentHistory is false
                    withConsultation: false,
                    date: this.$moment().format('YYYY-MM-DD'),
                    time: null,
                },
                options: {
                    treatments: [],
                    user: [],
                    clinic: [],
                    doctor: [],
                    availableDates: [],
                    availableSchedule: { morning: [], afternoon: [], evening: [] },
                },
                description: null,
                config: {},
                searchUserParam: { value: 'name', label: 'Name' },
                isLoading: false,
                isUserDataLoading: false,
                activeTab: { value: 'morning', label: 'Morning' },
                showSearchUser: true,
                CONFIRMATION_EXIT_PAGE_MODAL,
                CONFIRMATION_BOOK_APPOINTMENT_MODAL,
                EDIT_USER_MODAL,
                CREATE_USER_MODAL,
            }
        },
        mounted() {
            this.$moment.locale('en');
            if (this.hasAddPermission) {
                this.getBulkData();
            } else {
                window.location.assign('/offline-clinic-booking');
            }
        },
        beforeDestroy() {
            this.$root.$emit('addAdditionalInfo', '');
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasAddPermission() {
                return this.permissions.includes('offline_clinic_booking:add');
            },
            hasEditPermission() {
                return this.permissions.includes('offline_clinic_booking:edit');
            },
            generateEditUrl() {
                return `/offline-clinic-booking/user/${this.user.id}/edit?source=create-appointment`;
            },
            isSomeTreatmentRequireDoctor() {
                return this.form.treatments.some((treatment) => treatment.isRequireDoctor);
            },
            isValidTreatment() {
                if (!this.form.withConsultation && !this.form.treatments.length) {
                    return false;
                }
                return true;
            },
            isFormCompleted() {
                if (this.form.clinic && this.form.date) {
                    return this.isValidTreatment;
                }
                return false;
            },
            filteredTreatmentOptions() {
                let treatmentOptions = this.options.treatments.filter(filteredTreatment =>
                    this.form.treatments.every(everyTreatment => everyTreatment.id !== filteredTreatment.id)
                );
                if (this.form.clinic) {
                    treatmentOptions = treatmentOptions.filter(filteredTreatment =>
                        filteredTreatment.clinics.some(someClinic => someClinic.id === this.form.clinic.id));
                }
                return treatmentOptions;
            },
            sortedTreatmentsByPriority() {
                return [...this.form.treatments].sort((a, b) => a.priority - b.priority);
            },
            summaryData() {
                // sort treatment detail by priority
                const treatmentInformation = [...this.sortedTreatmentsByPriority];
                if (this.form.withConsultation) {
                    treatmentInformation.unshift({
                        id: 0,
                        title: 'Consultation',
                        price: this.config.consultation.price || 0,
                        durationInMinutes: this.config.consultation.durationInMinutes || 0,
                        type: '',
                        priority: 0,
                        isActive: true,
                        treatmentGroup: null,
                    })
                }
                return {
                    clinicName: this.form.clinic ? this.form.clinic.name : '-',
                    treatmentInformation,
                    doctor: {
                        name: (this.form.withConsultation || this.isSomeTreatmentRequireDoctor)
                            ? this.form.doctor.adminName : '-',
                        avatar: (this.form.withConsultation || this.isSomeTreatmentRequireDoctor)
                            ? this.form.doctor.avatar : '',
                    },
                    estimatedTotalPrice: treatmentInformation.reduce((acc, curr) => {
                        return acc + (curr.price || 0);
                    }, 0),
                    estimatedMinutes: treatmentInformation.reduce((acc, curr) => {
                        return acc + (curr.durationInMinutes);
                    }, 0),
                    treatmentDate: this.$moment(this.form.date).format('dddd, DD MMMM YYYY'),
                    treatmentTime: this.form.time || '-',
                }
            },
            userAvailableDates() {
                return this.options.availableDates.map((date) => date.date);
            }
        },
        methods: {
            getBulkData() {
                this.isLoading = true;
                Promise.all([
                    this.getTreatmentOptions(),
                    this.getUserOptions(),
                    this.getClinicOptions(this.getDoctorOptions),
                    this.getClinicBookingConfig(),
                ])
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            toggleStep() {
                if (this.step === 'form') {
                    this.step = 'summary';
                    this.$root.$emit('addAdditionalInfo', '<span style="color: #626262"> - Detail Booking</span>');
                    return;
                }
                this.step = 'form';
                this.$root.$emit('addAdditionalInfo', '');
                this.$forceUpdate();
            },
            addNewTreatment(newTreatment) {
                if (this.isLoading) return;
                this.form.treatments.push({
                    ...newTreatment,
                });
                this.options.availableSchedule = { morning: [], afternoon: [], evening: [] };
                this.getAvailableSchedule();
                this.$forceUpdate();
            },
            removeTreatment(selectedTreatmentId) {
                if (this.isLoading) return;
                this.form.treatments = this.form.treatments.filter((treatment) => treatment.id !== selectedTreatmentId);
                this.getAvailableSchedule();
                this.$forceUpdate();
            },
            sanitizeSearchUser(search) {
                if (this.searchUserParam.value === 'email') {
                    return search.replace(/ /g, '');
                }
                if (this.searchUserParam.value === 'mobileNumber') {
                    const trimmedValue = search.replace(/ /g, '');
                    if (trimmedValue.startsWith('+62')) {
                        return `0${trimmedValue.slice(3)}`;
                    }
                    return trimmedValue;
                }
                return search;
                
            },
            getTreatmentOptions(search, onSuccess = () => {}) {
                const params = {
                    isActive: true,
                    search,
                };
                this.axios
                    .get('/v2/misc/clinic-treatment-details-groups', { params })
                    .then((res) => {
                        this.options = {
                            ...this.options,
                            treatments: res.data.data,
                        };
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            text: err.response.data.message,
                        });
                    })
                    .finally(() => {
                        onSuccess();
                    });
            },
            getUserOptions(search) {
                const params = {
                    limit: 100,
                };
                if (search) {
                    params[this.searchUserParam.value] = this.sanitizeSearchUser(search);
                }
                this.axios
                    .get('/v2/clinical/offline-clinic-booking/users', { params })
                    .then((res) => {
                        this.options = {
                            ...this.options,
                            user: res.data.data.rows,
                        };
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            text: err.response.data.message,
                        });
                    });
            },
            getDoctorOptions(search) {
                const params = {
                    clinicRoleSlug: 'doctor',
                    search,
                    clinicId: this.form.clinic ? this.form.clinic.id : undefined,
                };
                this.axios
                    .get('/v2/misc/offline-clinic-teams', { params })
                    .then((res) => {
                        const doctorOptions = res.data.data.rows;
                        if (!search || 'any doctor'.includes(search.toLowerCase())) {
                            doctorOptions.unshift({ id: 0, adminName: 'Any Doctor', avatar: '' })
                        }
                        this.options = {
                            ...this.options,
                            doctor: doctorOptions,
                        };
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            text: err.response.data.message,
                        });
                    });
            },
            getClinicOptions(onSuccess = () => {}) {
                this.axios.get('/v2/misc/offline-clinics?isActive=true')
                    .then((res) => {
                        this.options.clinic = res.data.data.rows;
                        // auto select clinic
                        if (this.$store.state.account?.admin?.offlineClinic) {
                            this.form.clinic = this.$store.state.account.admin.offlineClinic;
                        } else {
                            this.form.clinic = res.data.data.rows.find((clinic) => clinic?.isDefault) ?? null;
                        }
                    })
                    .finally(() => {
                        onSuccess();
                    })
            },
            getClinicBookingConfig() {
                this.axios.get('/v2/clinical/offline-clinic-booking/config')
                    .then((res) => {
                        this.config = res.data.data;
                    })
            },
            generateDateOptions(selectedValue) {
                if (this.user.id) {
                    if (this.userAvailableDates.length > 0) {
                        return !this.userAvailableDates.includes(this.$moment(selectedValue).format('YYYY-MM-DD').toString());
                    }
                    // assumed it's loading or an error has occurred, so disabled all dates
                    return true;
                }
                return selectedValue.setHours(0, 0, 0, 0) < new Date(this.$moment()).setHours(0, 0, 0, 0)
                    || selectedValue.setHours(0, 0, 0, 0) > new Date(this.$moment().add(1, 'months')).setHours(0, 0, 0, 0);
            },
            generateScheduleTimeTooltip(currentValue) {
                if (!currentValue.isAvailable) return 'Unavailable';
                if (!currentValue.isOperationalHour) return 'Outside Ops Hours';
                if (!currentValue.isInOfflineClinicTeamSchedule) return "Outside Doctor's Schedule";
                return 'Available';
            },
            getAvailableDates() {
                this.form.date = null;

                this.axios.get(`/v2/clinical/offline-clinic-booking/get-available-dates?startDate=${this.$moment().format('YYYY-MM-DD')}&userId=${this.user.id}`)
                    .then((res) => {
                        this.description = res.data.data.description;
                        this.options.availableDates = res.data.data.dates;
                        const earliestAvailableDate = res.data.data.dates.find(
                            (findDate) => findDate.availability === 'AVAILABLE'
                        );
                        if (earliestAvailableDate) {
                            this.form.date = earliestAvailableDate.date;
                            this.getAvailableSchedule();
                        }
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                    })
            },
            getAvailableSchedule() {
                this.form.time = null;
                // if form is not completed, do not get available schedule
                if (!this.isFormCompleted) return;

                this.isLoading = true;
                const params = {
                    date: this.form.date,
                    offlineClinicId: this.form.clinic.id,
                    withConsultation: this.form.withConsultation,
                    offlineClinicTeamId: 
                        (this.form.doctor.id > 0 && (this.form.withConsultation || this.isSomeTreatmentRequireDoctor))
                            ? this.form.doctor.id : undefined,
                    clinicTreatmentDetailIds: this.form.treatments.map(treatment => treatment.id),
                };
                this.axios.post('/v2/clinical/offline-clinic-booking/get-available-schedule', params)
                    .then((res) => {
                        const newAvailableSchedule = res.data.data.reduce((acc, curr) => {
                            const [startTime] = curr.time.split(' - ');
                            const [startHour, startMinute] = startTime.split(':');
                            const totalStartMinutes = parseInt(startHour) * 60 + parseInt(startMinute);

                            if (totalStartMinutes < 720) {
                                acc.morning.push(curr);
                            } else if (totalStartMinutes < 1080) {
                                acc.afternoon.push(curr);
                            } else {
                                acc.evening.push(curr);
                            }

                            return acc;
                        }, { morning: [], afternoon: [], evening: [] });

                        const foundAvailableScheduleKey = Object.keys(newAvailableSchedule).find((key) =>
                            newAvailableSchedule[key].some((schedule) => schedule.isAvailable)
                        );

                        if (foundAvailableScheduleKey) {
                            this.activeTab = {
                                value: foundAvailableScheduleKey,
                                label: `${foundAvailableScheduleKey[0].toUpperCase()}${foundAvailableScheduleKey.slice(1)}`,
                            }
                        }
                        this.options.availableSchedule = newAvailableSchedule;
                    })
                    .catch((err) => {
                        this.options.availableSchedule = { morning: [], afternoon: [], evening: [] };
                        this.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.$forceUpdate();
                    });
            },
            setScheduleTime(newValue) {
                if (this.isLoading) return;
                if (newValue) {
                    this.form.time = newValue;
                    return;
                }
                this.form.time = null;
            },
            getUserData() {
                this.isUserDataLoading = true;
                this.axios
                    .get(`/v2/clinical/offline-clinic-booking/users/${this.user.id}`)
                    .then((res) => {
                        this.user = {
                            ...this.user,
                            ...res.data.data,
                            name: `${res.data.data.firstName || ''} ${res.data.data.lastName || ''}`.trimEnd(),
                        };
                        this.isUserDataLoading = false;
                    });
            },
            openEditUserModal() {
                this.getUserData();
                this.$modal.show(EDIT_USER_MODAL);
            },
            closeEditUserModal() {
                this.$modal.hide(EDIT_USER_MODAL);
            },
            openCreateUserModal() {
                this.$modal.show(CREATE_USER_MODAL);
            },
            closeCreateUserModal() {
                this.$modal.hide(CREATE_USER_MODAL);
            },
            openConfirmationExitPageModal() {
                this.$modal.show(CONFIRMATION_EXIT_PAGE_MODAL);
            },
            closeConfirmationExitPageModal(isRedirect = false) {
                this.$modal.hide(CONFIRMATION_EXIT_PAGE_MODAL);
                if (isRedirect) {
                    setTimeout(() => {
                        this.$router.push('/offline-clinic-booking');
                    }, 250);
                }
            },
            openConfirmationBookAppointmentModal() {
                this.$modal.show(CONFIRMATION_BOOK_APPOINTMENT_MODAL);
            },
            closeConfirmationBookAppointmentModal(isBookAppointment = false) {
                if (!isBookAppointment) {
                    this.$modal.hide(CONFIRMATION_BOOK_APPOINTMENT_MODAL);
                    return;
                }

                this.onBookAppointment();
            },
            async onSave() {
                const isValid = await this.$refs.observer.validate();
                if (isValid && this.isFormCompleted) {
                    if (this.user?.id && this.form.time) {
                        this.toggleStep();
                    } else {
                        const errorFields = [];
                        if (!this.user?.id) errorFields.push('user field');
                        if (!this.form.time) errorFields.push('schedule time');
                        const errorMessage = `Please select ${errorFields.join(' & ')}.`

						this.$notify({
							type: 'error',
							title: 'Error',
							text: errorMessage,
						});
                    }
                }
            },
            parseMinutesToTimeString(minutes) {
                const hoursValue = Math.floor(minutes / 60);
                const minutesValue = minutes % 60;
                const hourText = hoursValue > 1 ? 'hours' : 'hour';
                if (hoursValue > 0 && minutesValue > 0) return `${hoursValue} ${hourText} ${minutesValue} minutes`;
                if (hoursValue > 0 && minutesValue === 0) return `${hoursValue} ${hourText}`;
                return `${minutesValue} minutes`;
            },
            onBookAppointment() {
                this.isLoading = true;
                this.closeConfirmationBookAppointmentModal();

                const [startTime] = this.form.time.split(' - ');
                const params = {
                    date: this.$moment(this.form.date).format('YYYY-MM-DD'),
                    time: startTime,
                    offlineClinicId: this.form.clinic.id,
                    withConsultation: this.form.withConsultation,
                    offlineClinicTeamId: 
                        (this.form.doctor.id > 0 && (this.form.withConsultation || this.isSomeTreatmentRequireDoctor))
                            ? this.form.doctor.id : undefined,
                    clinicTreatmentDetailIds: this.form.treatments.map(treatment => treatment.id),
                    userId: parseInt(this.user.id),
                };

                this.axios.post('/v2/clinical/offline-clinic-booking', { ...params })
                    .then((res) => {
                        this.$swal.fire({
                            title: res.data.message,
                            text: 'New appointment created',
                            icon: 'success',
                            confirmButtonColor: '#0036A0',
                            confirmButtonText: 'OK'
                        })
                        
                        setTimeout(() => {
                            this.$router.push(`/offline-clinic-booking/${res.data.data.bookingNumber}`);
                        }, 250);
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                        
                        // back to form page
                        this.toggleStep();
                        this.getTreatmentOptions('', () => {
                            setTimeout(() => {
                                this.handleRemoveUnavailableTreatments(this.form.clinic);
                                this.getAvailableSchedule();
                            }, 250);
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            },
            handleCopyTreatmentInformation() {
                try {
                    const userName = this.user.name;
                    const clinicName = this.summaryData.clinicName;
                    const treatmentTime = `${this.$moment(this.form.date).locale('id').format('DD MMMM YYYY')}, ${this.form.time?.split?.(' - ')?.[0] ?? ''}`;
                    const doctor = () => {
                        switch(this.summaryData.doctor.name) {
                            case 'Any Doctor':
                                return '\nDokter: Dokter yang bertugas';
                            case '-':
                                return '';
                            default: 
                                return `\nDokter: ${this.summaryData.doctor.name}`;
                        }
                    };

                    let treatmentList = [...this.sortedTreatmentsByPriority].map((treatment) => treatment.title);
                    if (this.form.withConsultation) treatmentList.unshift('Konsultasi');
                    treatmentList = treatmentList
                        .map((treatment, treatmentIdx) => `${treatmentIdx + 1}. ${treatment}`)
                        .join('\n');

                    const treatmentInformation = `Halo ${userName},\nMohon konfirmasi Anda dengan perawatan yang terjadwal sebagai berikut:\n\nNama Pasien: ${userName}\nKlinik: ${clinicName}\nWaktu Perawatan: ${treatmentTime}${doctor()}\n\nDetail perawatan yang dipilih: \n${treatmentList}\n\nMohon membalas dengan kata “Benar” jika informasi di atas sudah sesuai.\nTerima kasih atas konfirmasinya.`;
                    navigator.clipboard.writeText(treatmentInformation);

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        text: 'Successfully copied treatment information to clipboard',
                    });
                } catch(err) {
                    this.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err?.message || 'Something went wrong',
                    });
                }
            },
            handleRemoveUnavailableTreatments(clinic) {
                if (!this.form.treatments.length) return;

                // update each treatment clinics to latest data
                this.form.treatments = this.form.treatments.map((currentTreatmentData) => {
                    const latestTreatmentData = this.options.treatments.find(
                        treatmentOption => treatmentOption.id === currentTreatmentData.id
                    );
                    return {
                        ...currentTreatmentData,
                        // if latest treatment data not found
                        // it means that the treatment is no longer available
                        // then fill clinics in currentTreatmentData with empty array to flagging the treatment as unavailable
                        clinics: latestTreatmentData ? latestTreatmentData.clinics : [],
                    }
                });

                const unavailableTreatments = this.form.treatments.filter(
                    treatment => !treatment.clinics.some(someClinic => someClinic.id === clinic.id)
                );
                if (unavailableTreatments.length > 0) {
                    this.form.treatments = this.form.treatments.filter(
                        treatment => !unavailableTreatments.find(
                            unavailableTreatment => unavailableTreatment.id === treatment.id
                        )
                    );
                    
                    let message = '';
                    if (unavailableTreatments.length === 1) {
                        message = `${unavailableTreatments[0].title} is unavailable`;
                    } else {
                        const treatmentNames = unavailableTreatments.map(t => t.title);
                        message = `${treatmentNames.join(', ')} are unavailable`;
                    }
                    
                    this.$notify({
                        type: 'error',
                        title: 'Unavailabe Treatment',
                        text: message,
                    });
                }
            },
            handleSelectClinic(clinic) {
                this.handleRemoveUnavailableTreatments(clinic);
                if (this.form.doctor.id > 0) {
                    // reset selected doctor preference
                    this.form.doctor = { id: 0, adminName: 'Any Doctor', avatar: '' };
                }
                // refetch schedule
                // and doctor options with new selected clinic id
                this.getAvailableSchedule();
                this.getDoctorOptions();
            }
        },
    }
</script>

<style scoped>
    .gap-0\.25 {
        gap: 0.25rem;
    }

    .gap-0\.5 {
        gap: 0.5rem;
    }

    .gap-0\.75 {
		gap: 0.75rem;
    }

	.gap-1 {
		gap: 1rem;
	}
    
    .mt-0\.25 {
        margin-top: 0.25rem;
    }
    
    .mr-0\.25 {
        margin-right: 0.25rem;
    }

    .selected-treatments {
        margin-bottom: 0.5rem;
        border: 1px solid #bcc0d1;
        padding: 0 12px;
        border-radius: 8px;
    }

    .schedule-loader {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #available-schedule {
        width: 360px;
        max-width: 100%;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        padding: 20px 24px;
    }

    .available-schedule-empty-state {
        padding-inline: 64px;
    }

    .btn-schedule-list {
        background-color: #fff;
        width: 100%;
        padding: 8px;
        border: 1px solid rgb(190, 194, 210);
        border-radius: 6px;
    }

    .btn-schedule-list-disabled {
        background-color: rgb(242, 244, 246);
        border: 1px solid rgb(242, 244, 246);
        color: rgb(152, 152, 154);
        cursor: not-allowed;
    }

    .btn-schedule-list-out-doctor-schedule {
        background-color: #fff6ee;
        border: 1px solid #fff6ee;
    }

    .btn-schedule-list-out-ops-hours {
        background-color: #FDEEEE;
        border: 1px solid #FDEEEE;
    }

    .btn-schedule-list-selected {
        background-color: #def0ff;
        border: 1px solid #1284e7;
    }

    #legend-schedule {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        padding: 24px;
    }
    
    .btn-legend-schedule {
        width: fit-content !important;
        padding: 12px 20px;
        border-radius: 6px;
        cursor: unset !important;
    }

    .circle {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0072cd;
        overflow: hidden;
    }

    .initial-user-name {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }

    .clinic-booking-rounded-img-size-48 {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 8px;
    }

    .td-border-bottom {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        border-bottom: 1px solid #bcc0d1 !important;
        background-color: transparent;
    }

    .td-no-border-bottom {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        background-color: transparent;
    }

    .modal-container {
        overflow: auto;
        padding: 1rem;
        height: 100%;
    }

    .btn-group-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        background-color: #f2f4f6;
    }

    .btn-cancel {
        border-radius: 8px;
        border: 1px solid #bcc0d1;
        color: #5b5e62;
        background-color: #ffffff;
        padding: 12.6px;
    }

    .btn-save {
        color: #ffffff;
        background-color: #0072cd;
        padding: 12.6px;
    }

    .btn-cancel:disabled, .btn-save:disabled {
        background-color: rgba(17, 24, 32, 0.4);
    }

    .btn-copy {
        border-radius: 8px;
        border: 1px solid #bcc0d1;
        color: #5b5e62;
        background-color: #ffffff;
        padding: 9.6px 12.6px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .btn-edit-appointment {
        border-radius: 8px;
        border: 1px solid #0072cd;
        color: #0072cd;
        background-color: #ffffff;
        padding: 12.6px;
    }

    @media (max-width: 768px) {
        .available-schedule-empty-state {
            padding-inline: 42px;
        }
    }
</style>
  
<style>
    #schedule-date .mx-calendar {
        padding: 16px;
        width: 300px;
        max-width: 100%;
    }

    #schedule-date .mx-datepicker-main {
        border-radius: 8px;
        border-color: #D9D9D9;
    }

    #schedule-date .mx-table-date td, #schedule-date .mx-table-date th {
        height: 36px;
        font-size: 15px;
        border-radius: 8px;
        overflow: hidden;
    }

    #schedule-date .mx-calendar-header .mx-btn {
        font-size: 15px;
    }

    #schedule-date .mx-calendar-content {
        height: 280px;
    }

    #schedule-date .mx-calendar-content .cell.not-current-month, #schedule-date .mx-calendar-content .cell.not-current-month:hover {
        color: #ccc !important;
        background: none !important;
        border: none !important;
    }

    #schedule-date .mx-table.mx-table-date {
        border-spacing: 4px;
    }

    #schedule-date .mx-calendar-content .cell {
        border: 1px solid #BCC0D1;
    }

    #schedule-date .mx-calendar-content .cell.disabled {
        border: none;
    }

    #schedule-date .mx-calendar-content .cell.active, #schedule-date .mx-calendar-content .cell:hover {
        background-color: #EBF8FA;
        border: 1px solid #0072CD;
        color: #73879c;
    }

    #schedule-date .mx-calendar-content .cell.disabled:hover {
        background-color: #f3f3f3;
        border: none;
        color: #ccc;
    }

    #booking-date-description {
        border: 1px solid #FFAE62;
        padding: 12px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
    }

    #booking-date-description > div > p {
        font-size: 12px;
        margin-bottom: 0 !important;
    }

    @media (max-width: 768px) {
        #schedule-date .mx-calendar {
            width: 260px;
        }
    }
</style>
