var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dropdown-menu',{ref:"dropdown",attrs:{"position":"right"}},[_c('div',{staticClass:"trigger-button",class:{ 'active-trigger-button': _vm.appliedFiltersCounter > 0 },attrs:{"slot":"trigger"},slot:"trigger"},[_c('span',[_c('i',{staticClass:"fa fa-filter"})]),_c('span',[_vm._v(_vm._s(_vm.triggerButtonLabel))])]),_c('div',{staticStyle:{"width":"350px"},attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"more-filter-content-header"},[_c('span',{staticClass:"text-black font-700"},[_vm._v("Filters")]),_c('span',{staticClass:"reset-button",on:{"click":_vm.reset}},[_vm._v("Reset")])]),_c('div',{staticClass:"more-filter-content"},[_c('div',[_c('p',{staticClass:"input-label"},[_vm._v("Booking Status")]),_c('div',{staticClass:"status-group"},_vm._l((_vm.bookingStatusOptions),function(option){return _c('div',{key:option.enum,staticClass:"status-item"},[_c('label',{staticClass:"status-item-input"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value.bookingStatus
                    .map(function (status) { return status.value; })
                    .includes(option.enum)},on:{"input":function (e) { return _vm.handleClickBookingStatus(
                      { value: option.enum, label: option.label },
                      e.target.checked
                    ); }}}),_c('Badge',{attrs:{"textColor":option.textColor,"bgColor":option.backgroundColor}},[_vm._v(" "+_vm._s(option.label))])],1)])}),0)]),_c('div',[_c('p',{staticClass:"input-label"},[_vm._v("Treatment Status")]),_c('div',{staticClass:"status-group"},_vm._l((_vm.bookingDetailStatusOptions),function(option){return _c('div',{key:option.enum,staticClass:"status-item"},[_c('label',{staticClass:"status-item-input"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value.bookingDetailStatus
                    .map(function (status) { return status.value; })
                    .includes(option.enum)},on:{"input":function (e) { return _vm.handleClickBookingDetailStatus(
                      { value: option.enum, label: option.label },
                      e.target.checked
                    ); }}}),_c('Badge',{attrs:{"textColor":option.textColor,"bgColor":option.backgroundColor}},[_vm._v(_vm._s(option.label))])],1)])}),0)]),_c('div',[_c('p',{staticClass:"input-label"},[_vm._v("Clinic Team")]),_c('InputMultiSelectCheckbox',{attrs:{"value":_vm.value.clinicTeams,"options":_vm.clinicTeamOptions,"searchable":true,"dropdownScopeClass":"more-filter-content"},on:{"search":_vm.getClinicTeamOptions,"input":function (val) { return _vm.$emit('input', Object.assign({}, _vm.value, {clinicTeams: val})); }}})],1),_c('div',[_c('p',{staticClass:"input-label"},[_vm._v("Room Type")]),_c('InputMultiSelectCheckbox',{attrs:{"value":_vm.value.roomTypes,"options":[
            { value: 'CONSULT', label: 'Consult Room' },
            { value: 'FACIAL', label: 'Facial Room' },
            { value: 'TREATMENT', label: 'Treatment Room' },
            { value: 'LASER', label: 'Laser Room' } ],"dropdownScopeClass":"more-filter-content"},on:{"input":_vm.handleChangeRoomType}})],1),_c('div',[_c('p',{staticClass:"input-label"},[_vm._v("Room Name")]),_c('InputMultiSelectCheckbox',{attrs:{"value":_vm.value.rooms,"options":_vm.clinicRoomOptions,"searchable":true,"dropdownScopeClass":"more-filter-content"},on:{"search":function (search) { return _vm.getClinicRoomOptions({ search: search, clinicId: _vm.clinicId }); },"input":function (val) { return _vm.$emit('input', Object.assign({}, _vm.value, {rooms: val})); }}})],1),_c('div',[_c('p',{staticClass:"input-label"},[_vm._v("Treatment Name")]),_c('InputMultiSelectCheckbox',{attrs:{"value":_vm.value.treatments,"options":_vm.clinicTreatmentOptions,"searchable":true,"dropdownScopeClass":"more-filter-content"},on:{"search":function (search) { return _vm.getClinicTreatmentOptions({ search: search, clinicId: _vm.clinicId }); },"input":function (val) { return _vm.$emit('input', Object.assign({}, _vm.value, {treatments: val})); }}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }